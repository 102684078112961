<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="6">
        <h1 class="mt-10  ">Fallo</h1>
      </v-col>
      <v-col cols="6" class="mt-10" v-if="this.formatosId">
        <CheckButtons 
          :tenderId="this.tenderId"
          :formatosId="this.formatosId"
        />
      </v-col>
      <v-col cols="12">
        <hr class="">
      </v-col>

      <v-col cols="12">
        <v-tabs
            background-color="primary"
            dark
        >
          <v-tab href="#form">
            Editar Estudio
          </v-tab>
          <v-tab-item value="form">
            <v-form ref="form" class="mt-10" @submit.prevent="submitForm">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Descripcion</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Descripcion</h1>
                        <trumbowyg
                            v-model="data.descripcion"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Fecha y Hora"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Antecedentes</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Antecedentes
                        </h1>
                        <trumbowyg
                            v-model="data.antecedentes"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Antecedentes"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Considerado</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Considerado
                        </h1>
                        <trumbowyg
                            v-model="data.considerando"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Considerado"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Resolutivo</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Resolutivo
                        </h1>
                        <trumbowyg
                            v-model="data.resolutivo"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Resolutivo"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Hora</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Hora
                        </h1>
                        <trumbowyg
                            v-model="data.hora"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Hora"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Pie representante</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <h1 class="mt-4 mx-2 ">Pie representante
                        </h1>
                        <trumbowyg
                            v-model="data.pie_representantes"
                            name="body"
                            :config="configs.advanced"
                            placeholder="Pie representante"
                            class="form-control"
                        ></trumbowyg>
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col cols="12" lg="6" class="mt-4">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-btn type="button" @click="generateData" color="primary" style="width: 200px">
                    Generar archivo
                  </v-btn>
                </v-col>
                <v-col cols="3">
                  <v-btn type="button" v-if="documentGenerateStatus" @click="downloadFileServer" color="primary" style="width: 200px">
                    Descargar archivo
                  </v-btn>
                </v-col>
                <v-col cols="12" v-if="documentGenerateStatus">
                  <h1 class="mt-4 mx-2 ">Visualización de documento
                  </h1>
                  <trumbowyg
                      v-model="documentGenerate"
                      disabled="disabled"
                      name="body"
                      :config="configs.advanced"
                      placeholder="Visualización de documento"
                      class="form-control"
                  ></trumbowyg>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab href="#files" @click="findTenderFile">
            Dictamen del fallo (Abjunto)
          </v-tab>
          <v-tab-item value="files">
            <v-form v-if="!dataFile.idFile" ref="form" class="mt-10" @submit.prevent="submitFormFileCreate">
              <v-row class="mb-4">
                <v-expansion-panels inset>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Adjunta Documento</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <FileInput
                            :tenderId="data.tenderId"
                            :formatosId="data.formatosId"
                            :file="dataFile.file"
                            @getImageFile="handleImageFile"
                            @changeShowImage="handleShowImage"
                        />
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col cols="12" class="mt-4">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-form v-else ref="form" class="mt-10" @submit.prevent="submitFormFileEdit">
              <v-row class="mb-4" >
                <v-expansion-panels inset>
                  <v-expansion-panel
                  >
                    <v-expansion-panel-header>Adjunta Documento</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-col cols="12">
                        <FileInputEdit
                            :tenderId="dataFile.tenderId"
                            :formatosId="dataFile.formatosId"
                            :file="dataFile.file"
                            :file-tmp="fileTmp"
                            @getImageFile="handleImageFileUpdate"
                            @changeShowImage="handleShowImageUpdate"
                        />
                      </v-col>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-col v-if="fileTmp" cols="6" class="mt-6">
                  <v-btn @click="downloadImage" type="button" color="primary" style="width: 200px">
                    Descargar archivo
                  </v-btn>
                </v-col>
                <v-col v-if="!fileTmp" cols="6" class="mt-6">
                  <v-btn type="submit" color="primary" style="width: 200px">
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

        </v-tabs>
      </v-col>


    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import CheckButtons from "../../components/CheckButtons";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'
import FileInputEdit from "@/components/FileInputEdit.vue";
import FileInput from "@/components/FileInput.vue";
import {URL_API_IMAGES} from "@/store/config";

export default {
  name: "DetailRulings",
  data() {
    return {
      overlay: false,
      tenderId: null,
      fileTmp: '',
      externalId: null,
      documentComplete:null,
      documentGenerate:null,
      documentGenerateStatus:false,
      id: null,

      formatosId:null,
      data: {
        descripcion: "",
        antecedentes: "",
        representante: '',
        considerando: '',
        resolutivo: '',
        hora: '',
        pie_representantes: '',
      },
      dataFile:{
        idFile: null,
        file:null,
        formatosId: 37,
        tenderId: null,
      },
      configs: {
        advanced: {
          autogrow: true,
          // Adding color plugin button
          btnsAdd: ['foreColor', 'backColor'],
          btnsDef: {
            // Create a new dropdown
            'btnGrp-image': {
              dropdown: ['base64'],
              ico: 'insertImage'
            }
          },
          // Limit toolbar buttons
          btns: [
            ['viewHTML'],
            ['formatting'],
            'btnGrp-semantic',
            ['undo', 'redo'],
            ['superscript', 'subscript'],
            ['btnGrp-image'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
            ['unorderedList', 'orderedList'],
            ['link'],
            ['btnGrp-justify'],
            ['btnGrp-lists'],
            ['horizontalRule'],
            ['foreColor'], ['backColor'],
            ['removeformat'],
            [['bold', 'italic'], ['link']]
          ]
        },
      },
    };
  },
  components: {
    FileInput, FileInputEdit,
    CheckButtons,
    Overlay,
    Trumbowyg
  },
  async created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.id = parseInt(this.$route.params.marketId);
    await this.findMarketOne();
  },
  methods: {
    ...mapActions('rulings', ['updateRulings', 'findOneRulings','generateHtmlFormat','downloadFile','findOneRulingsDic','createRulingsDic','updateRulingsFiles']),
    ...mapActions('tenders', ['findOneTenders']),
    async submitFormFileEdit() {
      if (!this.fileSend){
        this.sweetAlertWarning('Campo Documento es necesario');
        return
      }
      this.overlay = true;
      const response = await this.updateRulingsFiles({id: this.dataFile.idFile, data: this.fileSend});
      if (!response.error) {
        await this.findTenderFile();
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError('Error al editar')
      }
      this.overlay = false;
    },
    handleImageFileUpdate(file) {
      this.dataFile.file = file;
      this.fileSend = file;
      this.fileTmp = '';
    },

    handleShowImageUpdate(show) {
    },
    async submitFormFileCreate() {
      if (!this.dataFile.file){
        this.sweetAlertWarning('Campo Documento es necesario');
        return
      }
      this.overlay = true;

      const response = await this.createRulingsDic(this.dataFile);
      if (!response.error) {
        await this.findTenderFile();
        this.sweetAlertSuccess('Se creo correctamente')
      } else {
        this.sweetAlertError('Error al crear')
      }
      this.overlay = false;
    },
    handleImageFile(file) {
      this.dataFile.file = file;
    },
    handleShowImage(show) {
    },
    async findTenderFile(){
      const response = await this.findOneRulingsDic(this.tenderId);
      if (response){
        this.dataFile.file = response.file;
        this.dataFile.idFile = response.id;
        this.dataFile.formatosId = response.formatosId;
        this.dataFile.licitacionesId = response.licitacionesId;
        this.fileTmp = response.file;

      }else{
        this.dataFile.tenderId = this.tenderId;

      }
    },
    async findMarketOne() {
      const response = await this.findOneRulings({id: this.id, tenderId: this.tenderId});
      if (response) {

        this.documentComplete = response.documentoCompleto
        this.data.formatData = response.formatoData;
        this.data.descripcion = response.descripcion;
        this.data.representante = response.representante;
        this.data.antecedentes = response.antecedentes;
        this.data.considerando = response.considerando;
        this.data.resolutivo = response.resolutivo;
        this.data.hora = response.hora;
        this.data.pie_representantes = response.pie_representantes;
        this.formatosId = response.formatosId;
        const responseExternal = await this.findOneTenders(response.licitacionesId);
        if (responseExternal){
          this.externalId = responseExternal.claveLicitacion;
        }else{
          this.externalId =null;
        }
      } else {
        await this.$router.push(`/licitaciones_documentos/${this.tenderId}`)
      }
    },
    async validateRequired(){
      if (!this.data.descripcion){
        this.sweetAlertWarning('Campo descripcion es necesario');
        return false
      }

      if (!this.data.antecedentes){
        this.sweetAlertWarning('Campo Antecedentes es requerido');
        return false
      }
      if (!this.data.considerando){
        this.sweetAlertWarning('Campo Considerando es requerido');
        return false
      }
      if (!this.data.resolutivo){
        this.sweetAlertWarning('Campo Resolutivo es requerido');
        return false
      }
      if (!this.data.hora){
        this.sweetAlertWarning('Campo Hora es requerido');
        return false
      }
      if (!this.data.pie_representantes){
        this.sweetAlertWarning('Campo Pie de Representante es requerido');
        return false
      }
      return true

    },
    async submitForm() {
      const validateData = await this.validateRequired();
      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.updateRulings({id: this.id, data: this.data});
      if (!response.error) {
        await this.findMarketOne();
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },
    async downloadImage() {
      try {
        const url = `${URL_API_IMAGES}${this.dataFile.file}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error al descargar el archivo');
        }

        const blob = await response.blob();
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'archivo';

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          const matches = /filename="([^"]*)"/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1];
          }
        } else {
          const urlParts = url.split('/');
          fileName = urlParts[urlParts.length - 1];
        }

        // Crea un enlace temporal para descargar el archivo
        const urlBlob = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error al descargar el archivo', error);
      }
    },
    async generateData() {
      this.overlay = true;
      const response = await this.generateHtmlFormat({id: this.id});
      if (!response.error) {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
      } else {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },

    async downloadFileServer() {
      this.overlay = true;
      await this.downloadFile({id: this.id,externalId: this.externalId});
      this.overlay = false;
    },
    alertNexPrograming(){
      this.sweetAlertWarning('Proximamente')
    }
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
